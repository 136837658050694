.table-container {
    width: 100%;
    margin: auto;
    /* border-spacing: 0; */
}

table, td {
    /* border: 1px solid black; */
    border-collapse: collapse;
}

table {
    /* border: 1px solid lightgrey; */
    border-style: hidden;
    /* border-radius: 5px; */
}

tbody > tr:nth-child(even) {
    background: lightgrey;
}

.table-container tr {
    height: 45px;
}

.table-container tr td {
    padding: 0 10px;
}

/* .boxclass {
    border: 1px solid black;
} */

thead {
    /* background: #e6e6e6; */
    border-bottom: 1px solid darkgrey;
}

.title-column {
    max-width: 50vw;
}

.table-tfoot {
    text-align: center;
    border-top: 1px solid darkgrey;
}

/* .tbody {
    color: grey;
} */

/* .tbody tr:nth-child(even) {
    background: #e6e6e6;
} */

.page-button {
	padding: 0.5rem 0.8rem;
    /* border-radius: 5px; */
    margin: 5px 3px;
    border: 1px solid grey;
    border-radius: 5px;
}

.table-centered-checkbox {
    text-align: center;
    margin: auto;
}

.table-custom-checkbox {
    display: flex;
    justify-content: center;
    width: 100%;
    /* height: 21px; */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* .centered_button {
    text-align: center;
    margin: auto;
    display: block;
} */

.page-button-deactive {
    background: #fff;
    color: black;
}

.page-button-active {
    background: darkgrey;
    color: #fff;
    cursor: auto;
}

.table-button {
    border-radius: 5px;
    background: var(--button-main);
    border: 1px solid var(--button-hover);
    color: white;
    margin: auto;
    display: block;
    width: 90%;
    min-width: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 40px;
    transition: 0.3s;
}

.table-button:hover {
    background: var(--button-hover);
    transition: 0.3s;
}

.table-link {
    text-decoration: none;
    display: block;
}

.article-table {
    border: 1px solid lightgrey;
    border-radius: 10px;
}