.checkbox {
    width: 16px;
    height: 16px;
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}

.checked {
    width: 10px;
    height: 10px;
    background: black;
    border-radius: 3px;
}

.unchecked {
    display: none;
}