* {
    /* font-family: DM; */
    font-family: Georgia, 'Times New Roman', Times, serif;
    /* font-family: 'Caslon'; */
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
    unicode-bidi: plaintext;
    /* font-size: 11.5pt; */
}

/* @font-face {
    font-family:DM;
    font-style:normal;
    font-weight:300 900;
    font-display:fallback;
    src:url('./DMMono-Regular.ttf') format('truetype');
    font-stretch:normal;
} */

@font-face {
    font-family: Caslon;
    src: url('../public/static/media/fonts/LibreCaslonText-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight:100 500;
}

@font-face {
    font-family: Caslon;
    src: url('../public/static/media/fonts/LibreCaslonText-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight:600 900;
}

@font-face {
    font-family: Caslon;
    src: url('../public/static/media/fonts/LibreCaslonText-Italic.ttf') format('truetype');
    font-style: italic;
    font-weight:100 900;
}

#no-mobile::before {
    content: 'This website is currently unavailable on small displays.';
}

#no-mobile {
    display: none;
    text-align: center;
    width: 100%;
    height: 100%;
    padding: 10px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    line-height: 24px;
    overflow: auto;
}

@media screen and (max-width: 799px) {
    #root {
        display: none;
    }

    #no-mobile {
        display: flex;
    }
}

:root {
    --button-main: rgb(86, 123, 149);
    --button-hover: rgb(50, 104, 145);
    --button-disabled: rgb(113, 134, 149);
}