.tag-input {
    display: flex;
    flex-wrap: wrap;
    /* min-height: 48px; */
    /* line-height: 1.5; */
    padding: 0 8px;
    /* border: 1px solid #d6d8da;
    border-radius: 6px; */
    width: 100%;
    max-width: 500px;
}

.tag-input input {
    flex: 1;
    border: none;
    height: 50px;
    /* line-height: 1.5; */
    font-size: 14px;
    /* padding: 4px 0 0; */
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
}

.tag-input input:focus {
    outline: transparent;
}

.tag-input:has(input:focus) {
    /* border: 2px solid fo; */
    outline: medium auto currentColor;
    outline: medium auto invert;
    outline: 5px auto -webkit-focus-ring-color;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0;
}

.tag {
    width: auto;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 0 8px 8px 0;
    background: #0052cc;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
}

.tag-title {
    margin-top: 3px;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
}

.tag-close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
}