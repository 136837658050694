.login-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 600px;
}

.login-forminputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.login {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding: 20px;
    position: relative;
    gap: 20px;
    overflow: auto;
}

.login-button {
    background: var(--button-main);
    border: 1px solid var(--button-hover);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    transition: 0.3s;
}

.login-button:hover {
    background: var(--button-hover);
}

.login-button.disabled {
    color: lightgrey;
    background: var(--button-disabled);
    border: 1px solid var(--button-main);
    cursor: not-allowed;
}

.login-forminputs > label > input {
    font-size: 16px;
    display: flex;
    width: 100%;
}

.login-forminputs > label {
    display: flex;
    /* gap: 20px; */
    align-items: center;
    justify-content: center;
}

.login-label {
    width: 100pt;
}

.login-text {
    border: 1px solid lightgrey;
    border-radius: 5px;
    height: 30px;
    padding-left: 5px;
}

.login-text:focus {
    border: 1px solid black;
    outline: none;
}

.login-message {
    position: relative;
    /* margin-top: 236px; */
    color: red;
}

.login > img {
    /* margin-top: -200px; */
    margin-top: 7vh;
    margin-bottom: 40px;
}