.manage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.manage-side {
    /* flex-grow: 1; */
    border-bottom: 1px solid black;
    display: flex;
    flex-direction: row;
    width: 100%;
    /* justify-content: space-around; */
    justify-content: center;
}

/* .manage-main { */
    /* margin-top: 20px; */
    /* flex-grow: 9; */
    /* border: 1px solid black; */
/* } */

.manage-side-item {
    display: flex;
    align-items: center;
    height: 50px;
    /* width: calc(100% / 3); */
    width: 50%;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    background: #acb7bf;
    /* background: lightgrey; */
    color: white;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    /* margin-top: 20px; */
    /* border-bottom: 1px solid black; */

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.manage-side-item.selected {
    /* background: dimgrey; */
    background: var(--button-main);
    color: white;
    cursor: auto;
}