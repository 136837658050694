:root {
    --blue: #00b0f0;
    --header-height: 80px;
	/* font-family: Tahoma; */
}

html {
    box-sizing: border-box;
}

html, body, #root {
    height: 100%;
}

*, *:before, *:after {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
}

button {
    border: 1px solid black;
    color: black;
    padding: 12px;
    cursor: pointer;
    /* font-weight: bolder; */
}

.page-container {
    display: flex;
    width: 100%;
    height: 100%;
}

.page-container main {
    flex-basis: 80%;
}

.page-container aside {
    flex-basis: 20%
}
