.reset-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 600px;
}

.reset-forminputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.reset {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 20px;
}

.reset-button {
    background: var(--button-main);
    border: 1px solid var(--button-hover);
    border-radius: 5px;
    color: white;
    font-size: 16px;
    transition: 0.3s;
}

.reset-button:hover {
    background: var(--button-hover);
}

.reset-button.disabled {
    color: lightgrey;
    background: var(--button-disabled);
    border: 1px solid var(--button-main);
    cursor: not-allowed;
}

.reset-forminputs > label > input {
    font-size: 16px;
    display: flex;
    width: 100%;
}

.reset-forminputs > label {
    display: flex;
    /* gap: 20px; */
    align-items: center;
    justify-content: center;
}

.reset-label {
    width: 150pt;
}

.reset-text {
    border: 1px solid lightgrey;
    border-radius: 5px;
    height: 30px;
    padding-left: 5px;
}

.reset-text:focus {
    border: 1px solid black;
    outline: none;
}

.reset-message {
    text-align: center;
    color: dimgrey;
}

.not-available {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    flex-direction: column;
    gap: 20px;
}

.not-available > p {
    color: darkgrey;
}