.dashboard {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.dashboard-buttons {
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-bottom: 1px solid black;
    gap: 20px;
}

.dashboard-buttons > button, .dashboard-add-button > button {
    font-size: 16px;
    height: 50px;
    background: var(--button-main);
    color: white;
    border: 1px solid var(--button-hover);
    border-radius: 5px;
    transition: 0.3s;
}

.dashboard-buttons > button:hover, .dashboard-add-button > button:hover {
    background: var(--button-hover);
    transition: 0.3s;
}

.dashboard-add-button > button {
    min-width: 140px;
}

.dashboard-buttons > button.disabled, .dashboard-add-button > button.disabled {
    color: lightgrey;
    background: var(--button-disabled);
    border: 1px solid var(--button-main);
    cursor: not-allowed;
}

.dashboard-table {
    padding: 20px;
}

.dashboard-add {
    border-bottom: 1px solid black;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;
    gap: 20px;
    align-items: flex-end;
}

.dashboard-addform {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 20px;
    /* width: 100%; */
    /* max-width: 800px; */
}

.dashboard-addform > label {
    display: flex;
    /* max-width: 490px; */
}

.dashboard-input-label {
    position: relative;
    top: 5px;
    white-space: nowrap;
    margin-left: auto;
}

.dashboard-warnings {
    position: relative;
    top: 5px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.dashboard-file-warning {
    /* position: relative; */
    /* top: 5px; */
    white-space: nowrap;
    /* margin-left: 20px; */
}

.dashboard-input, .dashboard-file-input {
    border: 1px solid darkgrey;
    border-radius: 5px;
    height: 30px;
    width: 100%;
    margin-left: 7px;
    padding: 0px 10px;
    font-size: 16px;
}

.dashboard-input {
    width: 370px;
    margin-right: 0px;
}

.dashboard-file-input {
    border: none;
    max-width: 278px;
}

.dashboard-input:focus {
    outline: none;
    border: 1px solid black;
}

.hidden {
    display: none;
}

.red {
    color: red;
}

.dashboard-file-label {
    /* grid-column: 1 / -1; */
    grid-row: 4 / 5;
    display: flex;
    justify-content: flex-start;
}

.dashboard-add-button {
    grid-row: 3 / 5;
    grid-column: 2 / 3;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.dashboard-file-input::file-selector-button {
    border: 1px solid var(--button-hover);
    border-radius: 5px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    /* font-size: 14px; */
    /* font-family: DM; */
    background: var(--button-main);
    color: white;
    margin-right: 30px;
    height: 30px;
    transition: 0.3s;
}

.dashboard-file-input::file-selector-button:hover {
    background: var(--button-hover);
    transition: 0.3s;
}

@media screen and (max-width: 1000px) {
    .dashboard-input {
        width: calc(250px + 5vw)
    }
}