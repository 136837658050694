.responseview {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: calc(100% - 80px);
    text-align: center;
    /* gap: 10px; */
}

.responseview-empties-message {
    color: red;
    margin: 20px 20px 0px 20px;
}

.responseview-main {
    flex-grow: 4;
    flex-basis: 80vw;
}

.responseview-side {
    flex-grow: 1;
    /* padding-top: 30px; */

    border-top: 1px solid black;

    display: flex;
    flex-direction: column;
    flex-basis: 20vw;
}

.responseview-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    /* padding-top: 20px;
    padding-bottom: 20px; */
    border-bottom: 1px solid black;
}

.responseview-button {
    background: var(--button-main);
    border: 1px solid var(--button-hover);
    border-radius: 5px;
    color: white;
    width: 100%;
    margin: auto;
    display: block;
    font-size: 16px;
    transition: 0.3s;
}

.responseview-button.disabled {
    color: lightgrey;
    background: var(--button-disabled);
    border: 1px solid var(--button-main);
    cursor: not-allowed;
}

.responseview-button:hover {
    background: var(--button-hover);
}

.response {
    height: 100%;
    width: 100%;
}

.responseview-container {
    height: 100%;
    width: 100%;
}
.responseview-button.add {
    width: 100%;
}

.responseview-queries {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.responseview-query {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* height: 80px; */
    /* border-bottom: 1px solid black; */
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
}

.open-query {
    padding-right: 56px;
}

.closed-query {
    padding-right: 20px;
}

.responseview-querygroup {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* height: 80px; */
    border-bottom: 1px solid black;
}

.responseview-answer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 20px 20px 20px;
}

.responseview-answer-title {
    color: deepskyblue;
}

.responseview-question {
    margin-left: 20px;
    text-align: left;
    /* text-justify: inter-word; */
}

.responseview-answer-text {
    text-align: left;
    /* text-justify: inter-word; */
}

.responseview-delete {
    position: absolute;
    right: 0;
    margin-right: 20px;
    text-align: end;
    font-size: 36px;
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.responseview-add-query {
    padding-right: 5%;
    padding-left: 5%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.responseview-add-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.responseview-textarea {
    width: 100%;
    height: 80px;
    /* resize: none; */
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding: 7px;
    outline: none;
    /* margin-bottom: 20px; */
}

.responseview-textarea:focus {
    border: 1px solid black;
}

.hide {
    display: none;
}

.hidden {
    display: none;
}

.response {
    height: 100%;
    width: 100%;
}

.not-available {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    flex-direction: column;
    gap: 20px;
}

.not-available > p {
    color: darkgrey;
}

.responseview-button.disabled-button {
    color: lightgrey;
    border: 1px solid lightgrey;
    cursor:not-allowed;
}

.responseview-file {
    border-bottom: 1px solid black;
    /* height: 50px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
}

.responseview-fileselector > input {
    /* position: absolute; */
    /* left: 200px; */
    /* width: 400px; */
    font-size: 16px;
    width: 100%;
    /* margin-left: 21px; */
}

.red {
    color: red;
}

.responseview-filewarning {
    /* position: absolute; */
    /* left: 200px; */
    /* width: 400px; */
    /* font-size: 16px; */
    margin-top: 15px;
    /* margin-left: 21px; */
}

input::file-selector-button {
    border: 1px solid var(--button-hover);
    border-radius: 5px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    /* font-family: DM; */
    background: var(--button-main);
    color: white;
    margin-right: 10px;
    margin-left: 3.4vw;
    height: 30px;
}

input::file-selector-button:hover {
    background: var(--button-hover);
    transition: 0.3s;
}

.responseview-unselect {
    /* position: absolute; */
    /* right: auto; */
    /* margin-top: 10px; */
    /* margin-left: -60px; */
    /* text-align: end; */
    font-size: 36px;
    cursor: pointer;
    margin-left: 20px;
    /* height: 32px; */

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.responseview-fileselector {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.responseview-secondchance {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
}

.responseview-secondchance-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 0px 20px;
}

@media screen and (max-width: 1700px) {
    .responseview-side {
        flex-basis: 350px;
    }
}