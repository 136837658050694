.journals {
    /* margin: 100px; */
    font-size: 16pt;
    display: flex;
    padding: 20px 20px 20px 20px;
    flex-direction: column;
    gap: 40px;
    /* justify-content: center; */
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: auto;
}

.journals > ul {
    list-style: none;
}

.journals > ul > li {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

.journals > ul > li > img {
    width: 40px;
    margin-right: 16px;
    font-size: 12px;
}

a {
    /* text-decoration: none; */
    color: black;
}

.journals-logout {
    font-size: 16px;
    padding-bottom: 20px;
}

.journals-counter {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
}

.journals-counter-controls {
    display: flex;
    gap: 10px;
}

.journals-counter-controls > a {
    font-size: 16px;
}

.journals-table {
    margin-top: 20px;
    border: 1px solid lightgrey;
    border-radius: 10px;
}

.journals-table > table > tr {
    border: 1px solid lightgrey;
    cursor: pointer;
}

.journals-table > table > tr td {
    padding: 5px 10px;
}

.journals-table > table > tr > td:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: center;
}

.journals-table > table > tr > td > img {
    height: 60px;
    font-size: 12px;
}

.journals-table > table > tr > td:nth-child(2) {
    /* font-weight: bold; */
    /* font-weight: 500; */
}

.journals-counter-button {
    text-decoration: none;
    border: 1px solid black;
    border-radius: 5px;
    /* background: var(--button-main); */
    /* color: white; */
    height: 30px;
    min-width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 10px;
    transition: 0.3s;
}

.journals-counter-button:hover {
    border: 1px solid black;
    background: black;
    color: white;
    transition: 0.3s;
}

.journals-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--button-hover);
    border-radius: 20.5px;
    height: 41px;
    width: 84.4667px;
    background: var(--button-main);
    color: white;
    margin-left: auto;
    margin-right: 0px;
    padding: 0px 20.5px;
    /* height: 40px; */
    transition: 0.3s;
    text-decoration: none;
    font-size: 13.333333px;
}

.journals-logout:hover {
    border: 1px solid var(--button-hover);
    background: var(--button-hover);
    transition: 0.3s;
}