.header {
    width: 100%;
    min-height: 80px;
    position: sticky;
    /* border-bottom: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 20px;
    /* margin-bottom: 10px; */
    /* box-shadow: 0px 1px 8px 0px #000; */
}

.logout {
    justify-content: end;
    border: 1px solid var(--button-hover);
    border-radius: 20.5px;
    height: 41px;
    background: var(--button-main);
    color: white;
    margin-left: auto;
    margin-right: 0px;
    padding: 0px 20.5px;
    /* height: 40px; */
    transition: 0.3s;
}

.logout:hover {
    border: 1px solid var(--button-hover);
    background: var(--button-hover);
    transition: 0.3s;
}

.header-title {
    text-decoration: none;
    color: black;
    font-size: xx-large;
    margin-right: 24px;
}

.header > img {
    height: 60px;
    margin-right: 24px;
}

.header-image-link > img {
    height: 60px;
    margin-right: 24px;
}

.header-image-link {
    height: 60px;
    /* margin-right: 24px; */
}