.musers {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.musers-buttons {
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-bottom: 1px solid black;
    gap: 20px;
}

.musers-buttons > button, .musers-add > button {
    font-size: 16px;
    height: 50px;
    background: var(--button-main);
    color: white;
    border: 1px solid var(--button-hover);
    border-radius: 5px;
    transition: 0.3s;
}

.musers-buttons > button:hover, .musers-add > button:hover {
    background: var(--button-hover);
    transition: 0.3s;
}

.musers-buttons > button.disabled, .musers-add > button.disabled {
    color: lightgrey;
    background: var(--button-disabled);
    border: 1px solid var(--button-main);
    cursor: not-allowed;
}

.musers-add > button {
    min-width: 110px;
}

.musers-table {
    padding: 20px;
}

.musers-add {
    border-bottom: 1px solid black;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px;
    gap: 40px;
    align-items: flex-end;
}

.musers-addform {
    display: grid;
    grid-template-columns: repeat(1, auto);
    gap: 20px;
    /* width: 100%; */
    /* max-width: 800px; */
}

.musers-addform > label {
    display: flex;
}

.input-label {
    position: relative;
    top: 5px;
    white-space: nowrap;
    margin-left: auto;
}

.musers-input {
    border: 1px solid darkgrey;
    border-radius: 5px;
    height: 30px;
    width: 300px;
    margin-left: 7px;
    margin-right: 0px;
    padding: 0px 10px;
    font-size: 16px;
}

.musers-input:focus {
    outline: none;
    border: 1px solid black;
}

.hidden {
    display: none;
}