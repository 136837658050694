.articleview {
    display: flex;
    width: 100%;
    height: calc(100% - 80px);
    text-align: center;
    /* gap: 10px; */
    /* border-top: 1px solid black; */
}

.articleview-main {
    flex-grow: 4;
    flex-basis: 80vw;
}

.articleview-side {
    border-top: 1px solid black;
    flex-grow: 1;
    /* padding-top: 30px; */

    display: flex;
    flex-direction: column;
    flex-basis: 20vw;
    overflow: auto;
}

.articleview-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.articleview-button {
    background: var(--button-main);
    border: 1px solid var(--button-hover);
    border-radius: 5px;
    color: white;
    width: 100%;
    display: block;
    font-size: 16px;
    transition: 0.3s;
}

.articleview-button:hover {
    background: var(--button-hover);
}

.articleview-button.add {
    width: 100%;
}

.articleview-edit {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
}

.articleview-edit-section {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
}

.articleview-input {
    font-size: 16px;
    padding: 0px 5px;
    border: 1px solid darkgrey;
    border-radius: 5px;
    height: 30px;
}

.articleview-input:focus {
    border: 1px solid black;
    outline: none;
}

.articleview-file-input {
    font-size: 16px;
    /* padding: 0px 5px; */
    height: 30px;
    border: 0px;
    width: 100%;
}

.articleview-warnings {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.articleview-queries {
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100%;
}

.articleview-query {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    /* height: 80px; */
    /* border-bottom: 1px solid black; */
    padding-left: 20px;
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
}

.open-query {
    padding-right: 56px;
}

.closed-query {
    padding-right: 20px;
}

.articleview-querygroup {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* height: 80px; */
    border-bottom: 1px solid black;
}

.articleview-answer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 20px 20px 20px;
}

.articleview-answered-title {
    color: deepskyblue;
}

.red {
    color: red;
}

.articleview-notanswered-title {
    color: darkgoldenrod;
}

.articleview-question {
    margin-left: 20px;
    text-align: start;
    /* text-align: justify; */
    /* text-justify: inter-word; */
}

.articleview-answer-text {
    text-align: start;
    /* text-align: justify; */
    /* text-justify: inter-word; */
}

.articleview-delete {
    transform: translate(0%, -7%);
    font-size: 36px;
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.articleview-delete-container {
    position: absolute;
    height: 100%;
    right: 0;
    margin-right: 20px;
    text-align: end;
    display: flex;
    align-items: center;
}

.articleview-add-query {
    padding-right: 5%;
    padding-left: 5%;
    padding-top: 30px;
    padding-bottom: 30px;
}

.articleview-add-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.articleview-textarea {
    width: 100%;
    height: 200px;
    resize: none;
    border: 1px solid darkgrey;
    border-radius: 5px;
    padding: 7px;
    outline: none;
    margin-bottom: 20px;
}

.articleview-textarea:focus {
    border: 1px solid black;
}

.hide {
    display: none;
}

.articleview-button.disabled-button {
    color: lightgrey;
    background: var(--button-disabled);
    border: 1px solid var(--button-main);
    cursor:not-allowed;
}

.articleview-controls {
    border-bottom: 1px solid black;
}

.articleview-file {
    border-bottom: 1px solid black;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.articleview-secondtime {
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
}

@media screen and (max-width: 1700px) {
    .articleview-side {
        flex-basis: 350px;
    }
}