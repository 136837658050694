.oicc-app {
    /* max-width: 1000px; */
    /* width: 80%; */
    /* margin: 40px auto; */
    padding: 20px;
    background-color: #fff;
    /* border: 1px solid #ddd; */
    /* border-radius: 10px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    overflow: auto;
    display: flex;
    justify-content: center;
}

.oicc-container {
    width: 100%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.oicc-app * {
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
}

.oicc-header {
    /* background-color: #333; */
    /* #211d70
    #f47a55
    #5bcbf5 */
    color: #fff;
    padding: 10px;
    text-align: center;
    /* border-bottom: 1px solid #ddd; */
    border-radius: 10px;
    background-image: linear-gradient(120deg, #92d7f0 20%, #f0aa95 80%);
    width: 100%;
    max-width: 800px;
}

.oicc-header-title {
    margin: 0;
}

.oicc-form-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    max-width: 800px;
}

.oicc-form-container > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.oicc-form-field {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    /* margin-bottom: 20px; */
}

.oicc-form-field-label {
    font-weight: bold;
    width: 140px;
    /* color: #444; */
}

.oicc-form-field-input {
    /* width: 100%; */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.oicc-generate-button {
    width: fit-content;
    background-color: #838a9c;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* width: 200px; */
    /* background-image: linear-gradient(180deg, #aa9caa -30%, #53748b 130%); */
}

.oicc-generate-button:hover {
    /* background-color: #444; */
}

.oicc-latex-code-container {
    padding-top: 20px;
    padding-bottom: 20px;
}

.oicc-latex-code-title {
    margin-top: 0;
}

.oicc-latex-code-block {
    background-color: #f0f0f0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
    /* font-family: monospace !important; */
    font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
    /* counter-reset: line; */
}

#oicc-latex-code {
    font-size: 14px;
    font-family: monospace;
    line-height: 1.5;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    /* word-break: break-all !important; */
    white-space: pre-wrap !important;
    /* hyphens: auto !important; */
}

#oicc-latex-code span{
    font-family: monospace;
}

.oicc-latex-code-block code {
    display: inline-block;
    width: calc(100% - 40px);
    vertical-align: top;
    /* overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    white-space: pre-wrap;
    display: block; */
}

.oicc-code-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.oicc-wide-input {
    width:500px;
}

.oicc-form-field > textarea {
    font-size: 10pt;
    white-space: pre-wrap;
}

.oicc-author-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.oicc-author-names {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

.oicc-author-names > div > .oicc-form-field-label {
    width: auto;
    margin-right: 10px;
}

.oicc-emails > .oicc-form-field-label {
    width: auto;
    margin-right: 10px;
}

.oicc-orcid-input {
    width: 300px;
}

.oicc-email-input {
    width: 300px;
    margin-right: 10px;
}

.oicc-emails {
    display: flex;
    flex-direction: row;
    /* gap: 10px; */
    align-items: center;
    justify-content: flex-start;
}

.oicc-footnote {
    color: #aaa;
    font-size: small;
}

.oicc-affs-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 3px;
}

.oicc-author-container {
    align-items: flex-start;
}

.oicc-remove-button {
    font-size: xx-large;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 0px;
    padding-bottom: 3px;
}

.l-let {
    font-family: 'CMU Serif', cmr10, LMRoman10-Regular, 'Latin Modern Math', 'Nimbus Roman No9 L', 'Times New Roman', Times, serif !important;
}

.a-let {
    text-transform: uppercase; font-size: 0.75em; vertical-align: 0.25em; margin-left: -0.36em; margin-right: -0.15em; line-height: 1ex;
    font-family: 'CMU Serif', cmr10, LMRoman10-Regular, 'Latin Modern Math', 'Nimbus Roman No9 L', 'Times New Roman', Times, serif !important;
}

.e-let {
    text-transform: uppercase; vertical-align: -0.25em; margin-left: -0.1667em; margin-right: -0.125em; line-height: 1ex;
    font-family: 'CMU Serif', cmr10, LMRoman10-Regular, 'Latin Modern Math', 'Nimbus Roman No9 L', 'Times New Roman', Times, serif !important;
}